const InsertProductAddonImages = async () => {
	//  check if addon checkbox input exists
	if (document.querySelector(".single-product .wc-pao-addon-checkbox")) {
		// Get the product addon images
		const productAddonImages = document.querySelectorAll(".cat-addon-image img");

		// Add classes to the checkbox containers to make this easier to style
		const productAddonCheckboxes = document.querySelectorAll(
			".wc-pao-addon-container .form-row > div[class^='wc-pao-addon-']"
		);
		// add class to each
		productAddonCheckboxes.forEach((checkbox) => {
			checkbox.classList.add("wc-pao-addon-checkbox-container");
			let label = checkbox.querySelector("label");
			checkbox.setAttribute("title", label.innerText);
			// console.log(label.innerText);
			let innerSpan = document.createElement("span");
			innerSpan.classList.add("inner-label");
			label.insertBefore(innerSpan, label.firstChild);
			innerSpan.appendChild(label.childNodes[1]);
		});

		// Get the checkbox inputs
		const productAddonCheckboxInputs = document.querySelectorAll(
			'.wc-pao-addon-checkbox-container input[type="checkbox"]'
		);

		// call listener function
		productAddonCheckboxInputs.forEach((checkbox) => {
			checkbox.addEventListener("change", addCheckboxListener);
		});

		// Get the target elements
		const productAddonImageTargets = document.querySelectorAll(
			".wc-pao-addon-container .form-row > div[class^='wc-pao-addon-'] label"
		);
		// console.log(productAddonImageTargets);

		// Loop through the images and insert them into the product addon
		productAddonImages.forEach((productAddonImage, index) => {
			const productAddonImageSrc = productAddonImage.getAttribute("src");
			const productAddonImageAlt = productAddonImage.getAttribute("alt");
			const productAddonImageParent = productAddonImage.parentElement;

			// Create the image
			const image = document.createElement("img");
			image.setAttribute("src", productAddonImageSrc);
			image.setAttribute("alt", productAddonImageAlt);

			// Insert the image if the target exists
			if (productAddonImageTargets[index]) {
				productAddonImageTargets[index].insertAdjacentElement("beforeend", image);
			}
		});
	}
};

export default InsertProductAddonImages;

// function to add listeener to the checkbox
function addCheckboxListener() {
	// get the checkbox
	var checkbox = this;

	// log whether the checkbox is checked or not
	console.log(checkbox.checked);

	if (checkbox.checked) {
		// if checked, add the class
		checkbox.parentElement.classList.add("checked");
	} else {
		// if not checked, remove the class
		checkbox.parentElement.classList.remove("checked");
	}
}
