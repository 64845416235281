class Globals {
	constructor() {
		const closeMenu = document.getElementById("closeMenu");
		const menuTrigger = document.getElementById("menuTrigger");
		const closeSearch = document.getElementById("closeSearch");

		let flowerLeft = document.getElementById("flowerLeft");
		let flowerRight = document.getElementById("flowerRight");

		// if (flowerLeft) {
		// 	setTimeout(() => {
		// 		flowerLeft.classList.add("show");
		// 	}, 1000);
		// }
		// if (flowerRight) {
		// 	setTimeout(() => {
		// 		flowerRight.classList.add("show");
		// 	}, 1000);
		// }

		window.addEventListener(
			"resize",
			() => {
				if (window.innerWidth > 1023) {
					this.closeMenu();
				}
			},
			true
		);

		//Attach event listener to closeSearch.
		closeSearch.addEventListener("click", (e) => {
			this.closeSearch();
		});

		//Attach event listener to openSearch.
		document.querySelectorAll(".openSearch").forEach((openSearch) => {
			openSearch.addEventListener("click", (e) => {
				this.closeMenu();
				this.openSearch();
			});
		});

		//Attach event listener to closeMenu.
		closeMenu.addEventListener("click", (e) => {
			this.closeMenu();
		});

		//Attach event listener to open.
		menuTrigger.addEventListener("click", (e) => {
			this.openMenu();
		});

		//Add arrows to mobile menu for sub-menu trigger.
		const mobileMenu = document.getElementById("mobileMenu");
		mobileMenu.querySelectorAll(".menu-item-has-children").forEach((subMenu) => {
			subMenu.innerHTML = '<span class="open"></span>' + subMenu.innerHTML;

			subMenu.querySelector("span").addEventListener("click", (e) => {
				if (subMenu.classList.contains("active")) {
					subMenu.classList.remove("active");
				} else {
					subMenu.classList.add("active");
				}
				if (e.currentTarget.classList.contains("active")) {
					e.currentTarget.classList.remove("active");
				} else {
					e.currentTarget.classList.add("active");
				}
			});
		});

		mobileMenu.querySelectorAll("a").forEach((link) => {
			link.addEventListener("click", (e) => {
				this.closeMenu();
			});
		});
	}

	openMenu() {
		const body = document.querySelector("body");

		body.classList.add("active");
	}

	closeMenu() {
		const body = document.querySelector("body");

		body.classList.remove("active");
	}

	openSearch() {
		const body = document.querySelector("body");

		body.classList.add("search-active");
	}

	closeSearch() {
		const body = document.querySelector("body");

		body.classList.remove("search-active");
	}
}

export default Globals;
