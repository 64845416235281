import Masonry from "masonry-layout";
import GLightbox from "Parent/js/modules/glightbox/glightbox";
import imagesLoaded from 'imagesloaded';

class Weddings {

    constructor() {

        let grid = document.querySelectorAll('.wedding-photo');

        if(grid.length > 0) {
            const msnry = new Masonry( '.wedding-gallery-grid', {
                itemSelector: '.wedding-photo',
                columnWidth: '.grid-sizer',
                percentPosition: true
            });

            imagesLoaded( document.querySelector('.wedding-gallery-grid'), () => {
                msnry.layout();
            });

            const weddingGallery = new GLightbox({
                selector: '.wedding-photo'
            });
        }

        //Attach event listener to contact button to trigger default form.
        document.querySelectorAll('.flower-box').forEach((flowerButton) => {
            flowerButton.addEventListener("click", e => {

                e.preventDefault();

                const flowerBoxId = e.currentTarget.dataset.trigger;
                document.querySelector('.flower-box.active').classList.remove('active');
                e.currentTarget.classList.add('active');

                let contentBoxes = document.querySelectorAll('.inner-content');
                let targetBox = document.querySelector('.' + flowerBoxId);

                contentBoxes.forEach((box) => {
                    if(box.classList.contains('active')) {
                        box.classList.remove('active');
                        targetBox.classList.add('active');
                    }
                });

                targetBox.style.opacity = 1;
            });
        });
    }

}

export default Weddings;
